import React from "react"
import Layout from "../../components/Layout/Layout"
import PageHeader from "../../components/shared/PageHeader"
import CastBlock from "../../components/CastAndCreativePage/CastBlock"
import ImageBlock from "../../components/CastAndCreativePage/ImageBlock"
import CreativeBlock from "../../components/CastAndCreativePage/CreativeBlock";
import CourthouseAnimation from "../../components/Animations/CourthouseAnimation";
import {graphql, StaticQuery} from "gatsby"

const CastPage = (props) => (
	<Layout title="Cast & Creative | London | Back to the Future the Musical" description="Adapting the iconic story of Back to the Future The Musical for the stage are the movie’s creators Bob Gale (Back to the Future trilogy) and Robert Zemeckis (Forrest Gump). Playing now at the Adelphi Theatre in London.">
		<PageHeader
			imageXs={props.data.imageXs}
			imageSm={props.data.imageSm}
			imageLg={props.data.imageLg}
			title="Cast & Creative" />
		<CastBlock/>
		<ImageBlock/>
		<CourthouseAnimation/>
	</Layout>
)

export default (props) => (
	<StaticQuery
		query={graphql`
            query {
                imageXs: file(relativePath: { eq: "576.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 575,
                            quality: 100
                        )
                    }
                }
                imageSm: file(relativePath: { eq: "991.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 991,
                            quality: 100
                        )
                    }
                }
                imageLg: file(relativePath: { eq: "1920.png" }) {
                    childImageSharp {
                         gatsbyImageData(
                            width: 1740,
                            quality: 100
                        )
                    }
                }
            }
		`}
		render={data => (
			<CastPage
				{...props}
				data={data}/>
		)}
	/>
)